import { defineStore } from 'pinia'
import { readonly } from 'vue'

import { getBranchData } from '@/utils/api-service'
import { sleep } from '@/utils/time'

import type {
  LanguageTourCatalog,
  AllData,
  LanguageExhibits,
  LanguageStrings,
  MediaAsset,
  Settings,
  SupportedLanguage,
} from 'types/data'
import type { DeepReadonly } from 'vue'

interface DataState {
  settings: DeepReadonly<Settings>
  languageStrings: DeepReadonly<LanguageStrings[]>
  languages: DeepReadonly<SupportedLanguage[]>
  languageExhibits: DeepReadonly<LanguageExhibits[]>
  languageTourCatalogs: DeepReadonly<LanguageTourCatalog[]>
  displaySyncUrl: string
  token: string
}

export const useDataStore = defineStore('data', {
  state: (): DataState => ({
    settings: readonly({
      environment: '',
      demoModeTags: '',
      previewMode: false,
      useBlobStorage: false,
      mqtt: {
        brokerHost: '',
        brokerPort: 0,
        brokerWsPort: 0,
        password: '',
        clientLocalHost: null,
        username: '',
      },
      certificate: {
        cert: '',
        key: '',
        dev: false,
      },
      branch: {
        apiUrl: '',
        branchId: 0,
      },
      hasContentHubTours: false,
      hasLocationSupport: false,
      logLocationChanges: false,
      logTagFilter: '',
      logTagPosition: false,
    }),
    languageStrings: readonly([]),
    languages: readonly([]),
    languageExhibits: readonly([]),
    languageTourCatalogs: readonly([]),
    displaySyncUrl: '',
    token: '',
  }),

  getters: {
    museumType (): 'traditional' | 'location' {
      return this.settings.hasLocationSupport ? 'location' : 'traditional'
    },
    useBlobStorage (): boolean {
      return this.settings.previewMode ||
        this.settings.useBlobStorage
    },
  },

  actions: {
    async _internalLoadData (jsonUrl: string): Promise<AllData> {
      const res = await fetch(`${jsonUrl}`)
      if (!res.ok) {
        console.log(`Data response was not ok: ${res.statusText}`)
        throw new Error('Failed to load data')
      }
      return await res.json()
    },
    /**
     * @returns `true` if the data was loaded successfully, `false` if not
     */
    async loadData (): Promise<boolean> {
      let data: AllData | null = null
      let attempts = 0
      const loader$ = document.getElementById('data-loader')
      const branchError$ = document.getElementById('branch-error')
      const dataError$ = document.getElementById('data-error')
      while (!data) {
        try {
          const branchDataRes = await getBranchData()
          if (!branchDataRes.isDeployed) {
            if (loader$) loader$.style.display = 'none'
            if (branchError$) branchError$.style.display = 'block'
            const branchErrorUndeployed = document.getElementById('branch-error-undeployed')
            if (branchErrorUndeployed) branchErrorUndeployed.style.display = 'block'
            return false
          }
          if (branchDataRes.jsonUrl === 'Access Denied') {
            if (loader$) loader$.style.display = 'none'
            if (branchError$) branchError$.style.display = 'block'
            const branchErrorIp = document.getElementById('branch-error-ip')
            if (branchErrorIp) branchErrorIp.style.display = 'block'
            const branchErrorIpSpan = document.getElementById('branch-error-ip-span')
            if (branchErrorIpSpan) branchErrorIpSpan.innerText = branchDataRes.ipAddress || 'Unknown'
            return false
          }
          data = await this._internalLoadData(branchDataRes.jsonUrl)
          this.displaySyncUrl = branchDataRes.displaySyncUrl
          this.token = branchDataRes.token
        } catch (err) {
          console.log(err)
          if (++attempts >= 3) {
            console.log('Failed to load data 3 times, showing user error')
            if (loader$) loader$.style.display = 'none'
            if (dataError$) dataError$.style.display = 'flex'
            return false
          } else {
            console.log(`Failed to load data ${attempts} times, retrying...`)
            await sleep(1000)
          }
        }
      }
      this.settings = readonly(data.settings)
      this.languageStrings = readonly(data.languageStrings as LanguageStrings[])
      this.languages = readonly(data.languages as SupportedLanguage[])
      this.languageExhibits = readonly(data.languageExhibits as LanguageExhibits[])
      this.languageTourCatalogs = readonly(data.languageTourCatalogs as LanguageTourCatalog[])
      return true
    },
    getMediaSrc (media: MediaAsset | null): string {
      if (!media) return ''

      if (this.useBlobStorage) {
        if (!media.sas) return ''
        const baseSas = media.sas.split('?')[0]
        return `${baseSas}${this.token}`
      } else {
        return `/${media.localPath}`
      }
    },
  },
})
